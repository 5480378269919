.navbar{
    position: fixed;
    height: var(--header-height);
    justify-content: space-between;
    background-color: var(--white);
}

.navbar-logo{
    /* If this is set to 75% it doesn't work on iOS (though the problem doesn't 
    show up on Chrome); calculating it based on the variable solves this. */
    height: calc(var(--header-height) * 0.75);
}

.navbar-logo img{
    height: 100%;
    width: auto;
    margin-left: 10px;
}

.nav-links li{
    display: inline;
    margin: 0em 1.5em;
    font-family: amity;
    font-size: 25px;
    color: var(--black);
}

.navbar a,
.nav-links a{
    color: var(--black);
    text-decoration: none;
    cursor: pointer;
}


/** TRANSITION TO IPAD: **/
/** This 850px breakpoint is also a constant in the Navbar component **/
@media screen and (max-width: 850px) {
    .nav-links li{
        font-size: 20px;
        margin: 0px 15px;
    }
}


/** TRANSITION TO PHONE **/
@media screen and (max-width: 650px) {
    .nav-links li{
        margin: 0px 10px;
    }
  }
  