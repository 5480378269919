:root{
  /* APP-WIDE */
  --header-height: 60px;
  --footer-height: 20vh;
  --min-app-height: calc(100vh + var(--footer-height));
  --full-page-height: calc(100vh - var(--header-height));
  --page-width: 100vw;
  --max-page-width: 1500px;
  --showcase-height: 25vh;
  --skinny-profile: 80%;
  --max-skinny-profile: 800px;
  --thumbnail-size: 15vh;
  --border-radius: 20px;
  --text-field-height: 30px;
  --text-field-font-size: 16px;

  /* COLORS */
  --black: #000000;
  --light-black: #2e2e2e;
  --grey: #454545;
  --medium-grey: #585858;
  --light-grey: #aaaaaa;
  --very-light-grey: #e0e0e0;
  --dark-white: #f0f0f0;
  --red: #c80000;
  --very-light-red: #ffe4e9;
  --blue: #0055b6;
  /*--blue: #71008d;*/
  --light-blue: #4992e6;
  --very-light-blue: #d9ebff;
  --yellow: #faff64;
  --purple: #8500A5;
  --white: #ffffff;
}

@font-face {
  font-family: amity;
  src: url(./fonts/amityJack.ttf);
}

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: var(--black);
  text-align: center;
} 

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-x: hidden; */
  overflow: overlay;
  background-color: var(--yellow);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/** MAXIMALLY FLEXIBLE FLEXBOX CLASSES **/

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.col{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}


/* wraps the whole app (including header and footer) */
.app {
  justify-content: space-between;
  text-align: center;
  width: 100vw;
  height: auto;
  min-height: var(--min-app-height);
}

/* wraps everything except header and footer */
.app-wrap{
  width: 100vw;
  height: auto;
  min-height: var(--full-page-height);
  justify-content:flex-start;
}

/* wraps any page */
.full-page{
  height: auto;
  min-height: var(--full-page-height);
  width: var(--page-width);
  max-width: var(--max-page-width);
  background-color: white;
  justify-content: start;
}

/* Images */
img {
  width: 100%;
}

/** SCROLLBAR **/
/* width */
::-webkit-scrollbar {
  width: 10px;
  z-index: -10;
}

/* Track */
::-webkit-scrollbar-track {
/*   background: white; */
  background-color: transparent;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* Setting bg color to 0,0,0,0 makes it invisible --
    only visible on hover, below, when it becomes light grey; */
  border: 1px solid rgba(0,0,0,0);
  background-clip: padding-box;
  border-radius: var(--border-radius);
  background-color: var(--grey); 
  /* background-color: rgba(0,0,0,0); */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border: 1px solid rgba(0,0,0,0);
  background-clip: padding-box;
  background-color: var(--light-grey); 
}

/** INVALID URL **/
.invalid-url {
  margin-top: 80px;
  margin-bottom: 80px;
  justify-self: space-around !important;
  width: var(--skinny-profile);
  max-width: var(--max-skinny-profile);
}

.invalid-url img{
  border-radius: var(--border-radius);
}

.invalid-profile-url h3{
  text-align: center;
}

/** ALERTS / ERRORS **/
.alert{
  padding: 20px;
  border: 1px solid var(--blue);
  border-radius: var(--border-radius);
  background-color: var(--very-light-blue);
  margin: 20px 0px;
}

.alert p{
  width: 100%;
  text-align: left;
  margin-top: 10px;
}

.alert *{
  color: var(--blue);
}

.alert-error{
  background-color: var(--very-light-red);
  border: 1px solid var(--red);
}

.alert-error *{
  color: var(--red);
}

/** LOADING SPINNER **/

.loading {
  margin: auto;
  justify-self: space-around !important;
}

/** APPLICABLE EVERYWHERE **/
.unstyle-links,
.unstyle-links *{
  text-decoration: none;
}

/** TRANSITION TO IPAD PRO/SMALL LAPTOP: **/
@media screen and (max-width: 1100px) {
  .to-col-tablet-lg{
    flex-direction: column;
  }
}

/** TRANSITION TO TABLET: **/
@media screen and (max-width: 850px) {
  .to-col-tablet{
    flex-direction: column;
  }

  .unstick-tablet{
    position: relative;
  }
}

/** TRANSITION TO PHONE **/
@media screen and (max-width: 650px) {
  .full-page{
    width: 95vw;
  }

  .to-col-phone{
    flex-direction: column;
  }

  .to-row-phone{
    flex-direction: row;
  }

  .grid-2-col{
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .unstick-phone{
    position: relative;
  }
}
